var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"FormValidation"},[_c('b-form',{staticClass:"mb-3"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Color Type","label-for":"Color Type"}},[_c('validation-provider',{attrs:{"name":"color type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Color Type"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Color Code","label-for":"Color Code"}},[_c('validation-provider',{attrs:{"name":"color code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":(errors.length > 0 ? false : _vm.colorCodePattern),"placeholder":"Color Code"},on:{"keydown":_vm.onlyNumbers,"wheel":function($event){$event.preventDefault();}},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.colorCodePattern === false && !errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("Please type exactly 4 digits")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Color Name","label-for":"Color Name"}},[_c('validation-provider',{attrs:{"name":"color name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Color Name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Color Sample","label-for":"Color Sample"}},[_c('validation-provider',{attrs:{"name":"color sample","rules":!_vm.formData.id ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"color-img-sample",on:{"click":function($event){return _vm.$refs.myFile.click()}}},[_c('img',{staticClass:"attachment-svg",class:_vm.formData.imageThumbnail ? 'd-none' : '',attrs:{"src":require('@/assets/images/svg/paperclip.svg'),"alt":""}}),_c('b-form-file',{staticClass:"hidden",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Color Sample"},model:{value:(_vm.formData.image),callback:function ($$v) {_vm.$set(_vm.formData, "image", $$v)},expression:"formData.image"}}),_c('input',{ref:"myFile",staticClass:"hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onFileChange}}),(_vm.formData.imageThumbnail)?_c('div',{staticClass:"d-flex w-100 align-items-center",staticStyle:{"gap":"10px"}},[_c('BImgLazy',{staticClass:"color-img-sample",attrs:{"src":_vm.formData.imageThumbnail+ (_vm.isPreview ? '': '?'+Math.random()),"alt":"color sample"}}),_c('div',{staticClass:"btn btn-light"},[_vm._v("change")])],1):_c('div',{staticClass:"color-img-sample placeholder",class:errors.length > 0  ? 'form-control is-invalid': ''},[_vm._v(" Click here to add color sample file ")])],1),(!errors[0] && _vm.maxSizeError)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.maxSizeError))]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit(false)}}},[_vm._v(" "+_vm._s(_vm.action)+" ")])],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }