import config from "@/core/config/httpConfig"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Vue from "vue"

export default class HttpService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig => Will be used by this service
    httpConfig = {}

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns) {
        this.axiosIns = axiosIns
        this.httpConfig = {...config}
        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            configuration => {
                // Get token from localStorage
                const accessToken = this.getToken()
                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    configuration.headers.Authorization = `${this.httpConfig.tokenType} ${accessToken}`
                }
                configuration.headers.Accept = 'application/json'
                return configuration
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => {
                if (response && response.data.status_code === 401) {
                    if (window.location.href.match('login') == null) {
                        localStorage.clear()
                        window.location.href = "/login"
                    }
                }
                return response
            },
            error => {
                // const { config, response: { status } } = error
                const {config, response} = error
                if (response && response.status === 401) {
                    if (window.location.href.match('login') == null) {
                        localStorage.clear()
                        window.location.href = "/login"
                    }
                    Vue.prototype.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Authentication Error!`,
                            icon: 'CoffeeIcon',
                            variant: 'danger',
                            text: `Credentials not valid`,
                        },
                    })
                } else {
                    return Promise.reject(error)
                }
            },
        )
    }


    getToken() {
        return localStorage.getItem(this.httpConfig.storageTokenKeyName)
    }
}
