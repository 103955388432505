import CrudService from "@/services/shared/http/CrudService";

export default class ColorService extends CrudService {
    constructor(params = {}) {
        super("colors", params);
    }

    changeStatus(id, value) {
        return this.api.PATCH(`color-active-inactive/${id}`, {
            status: value,
        });
    }

    updateColor(id, formData) {
        return this.api.POST(this.endPoint + '/update/' + id, formData)
    }
}
