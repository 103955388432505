import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Vue from "vue"

export default function useAlert() {

    const alertMessage = ({message, status, status_code, errors}) => {
        Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
                title: status ? 'Success' : 'Error',
                icon: status ? '' : 'AlertTriangleIcon',
                variant: status ? 'success' : 'danger',
                // eslint-disable-next-line camelcase
                text: message ? message : (status_code ? errors[0].toString() : 'Something went wrong 😱'),
            },
        }, {
            position: 'top-right',
        })
    }

    const SuccessAlertMessage = (message) => {
        Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
                title: 'Success',
                icon: '',
                variant: 'success',
                text: message,
            },
        }, {
            position: 'top-right',
        })
    }

    return {
        alertMessage,
        SuccessAlertMessage
    }
}
