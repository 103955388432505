<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem;" variant="primary"/>
    </div>
    <div class="card">
      <div class="card-header">
        <h3 class="text-primary">{{ title }}</h3>
      </div>
      <div class="card-body">
        <validation-observer ref="FormValidation">
          <b-form class="mb-3">
            <b-row>
              <b-col md="6">
                <b-form-group label="Color Type" label-for="Color Type">
                  <validation-provider #default="{ errors }" name="color type" rules="required">
                    <b-form-input v-model="formData.type" :state="errors.length > 0 ? false : null"
                                  placeholder="Color Type"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Color Code" label-for="Color Code">
                  <validation-provider #default="{ errors }" name="color code" rules="required">
                    <b-form-input v-model="formData.code"
                                  type="number"
                                  @keydown="onlyNumbers"
                                  @wheel.prevent
                                  :state="(errors.length > 0 ? false : colorCodePattern) "
                                  placeholder="Color Code"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="colorCodePattern === false && !errors.length > 0"
                           class="text-danger">Please type exactly 4 digits</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Color Name" label-for="Color Name">
                  <validation-provider #default="{ errors }" name="color name" rules="required">
                    <b-form-input v-model="formData.name" :state="errors.length > 0 ? false : null"
                                  placeholder="Color Name"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Color Sample" label-for="Color Sample">
                  <validation-provider #default="{ errors }" name="color sample"
                                       :rules="!formData.id ? 'required' : ''">
                    <div @click="$refs.myFile.click()" class="color-img-sample">
                      <img class="attachment-svg" :class="formData.imageThumbnail ? 'd-none' : ''"
                           :src="require('@/assets/images/svg/paperclip.svg')" alt="">
                      <b-form-file class="hidden" v-model="formData.image" :state="errors.length > 0 ? false : null"
                                   placeholder="Color Sample"/>
                      <input ref="myFile" type="file" accept="image/*" class="hidden" @change="onFileChange">
                      <div v-if="formData.imageThumbnail"
                           class="d-flex w-100 align-items-center" style="gap: 10px">
                        <BImgLazy class="color-img-sample"
                                  :src="formData.imageThumbnail+ (isPreview ? '': '?'+Math.random())"
                                  alt="color sample"/>
                        <div class="btn btn-light">change</div>
                      </div>
                      <div v-else
                           :class="errors.length > 0  ? 'form-control is-invalid': ''"
                           class="color-img-sample placeholder">
                        Click here to add color sample file
                      </div>
                    </div>
                    <small v-if="!errors[0] && maxSizeError" class="text-danger">{{ maxSizeError }}</small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button variant="primary"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          class="float-right"
                          @click.prevent="submit(false)"
                >
                  {{ action }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>

  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import Ripple from "vue-ripple-directive";
import ColorService from "@/services/ColorService";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: 'Create Color'
    },
    action: {
      type: String,
      default: 'Save'
    },
    formData: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          type: 'RAL',
          name: '',
          code: '',
          image: null,
          imageThumbnail: null,
          defaultImageThumbnail: null,
        }
      },
    },
  },
  data() {
    return {
      required,
      defaultFormData: {
        type: 'RAL',
        name: '',
        code: '',
        image: null,
        imageThumbnail: null,
        defaultImageThumbnail: null,
      },
      loading: false,
      ColorService: new ColorService(),
      isPreview: false,
      maxSizeError: undefined
    }
  },
  computed: {
    colorCodePattern() {
      // 4 digits exactly
      if (!this.formData.code.length) {
        return null
      } else {
        return this.formData.code.length === 4 ? null : false
      }
    }
  },
  methods: {
    submit() {
      this.$refs.FormValidation.validate().then(success => {
        if (success) {
          this.formSubmitted()
        }
      })
    },
    onlyNumbers(event) {
      console.log(event)
      if (event.key === 'e' ||
          event.key === 'E' ||
          event.key === '.' ||
          event.key === '+' ||
          event.key === '-' ||
          event.key === 'ArrowDown' ||
          event.key === 'ArrowUp') {
        event.preventDefault()
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      if (files[0].size >= 2000000) {
        this.maxSizeError = 'Max file size is 2MB'
        this.formData.image = null
        if (this.formData.id) {
          this.formData.imageThumbnail = this.formData.defaultImageThumbnail
        } else {
          this.formData.imageThumbnail = null
        }
        return;
      }
      this.maxSizeError = undefined
      this.formData.image = files[0]
      this.createImage(files[0])
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.formData.imageThumbnail = e.target.result
        this.isPreview = true
      }
      reader.readAsDataURL(file)
    },
    formSubmitted() {
      if (!this.formData.id) {
        this.createColor()
      } else {
        this.updateColor()
      }
    },
    createColor() {
      this.loading = true
      const formData = new FormData()
      formData.append('type', this.formData.type)
      formData.append('code', this.formData.code)
      formData.append('name', this.formData.name)
      formData.append('image', this.formData.image)
      formData.append('status', 'true')
      this.ColorService.create(formData).then(res => {
        console.log(res)
        this.loading = false
        this.formData = Object.assign({}, this.defaultFormData)
        this.$refs.FormValidation.reset();
        this.$router.push({name: 'all-colors'})
      }, err => {
        console.log(err)
        this.loading = false
      })
    },
    updateColor() {
      this.loading = true
      const formData = new FormData()
      formData.append('type', this.formData.type)
      formData.append('code', this.formData.code)
      formData.append('name', this.formData.name)
      if (this.formData.image)
        formData.append('image', this.formData.image)
      this.ColorService.updateColor(this.formData.id, formData).then(res => {
        console.log(res)
        this.loading = false
        this.formData = Object.assign({}, this.defaultFormData)
        this.$refs.FormValidation.reset();
        this.$router.push({name: 'all-colors'})
      }, err => {
        console.log(err)
        this.loading = false
      })
    },
    test(value) {
      console.log(value);
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.loader-overlay ~ div {
  filter: blur(3px);
}

.color-img-sample {
  width: 100%;
  height: 38px;
  border-radius: 0.357rem;
  display: flex;
  align-items: center;
  object-fit: cover;
  cursor: pointer;
}

.color-img-sample.placeholder {
  border: 1px solid #d8d6de;
  padding-left: 1rem;
  color: #B9B9C3;
}

.color-img-sample.placeholder.is-invalid {
  border-color: #ea5455;
}

.attachment-svg {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
}

.hidden {
  display: none;
  visibility: hidden;
}

</style>
