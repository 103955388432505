import ApiService from "@/services/shared/http/ApiService"

export default class CrudService {
    endPoint = null
    api = new ApiService()

    constructor(endPoint) {
        this.endPoint = endPoint
    }

    index(params = {}) {
        return this.api.GET(this.endPoint, params)
    }

    show(id, params = {}) {
        return this.api.GET(this.endPoint + '/' + id, params)
    }

    update(id, data, params = {}) {
        return this.api.PUT(this.endPoint + '/' + id, data, params)
    }

    create(data, params = {}, showSuccessAlert = true) {
        return this.api.POST(this.endPoint, data, params, showSuccessAlert)
    }

    delete(id, params = {}) {
        return this.api.DELETE(this.endPoint + '/' + id, params)
    }
}
