import HttpService from "./httpService"
import axios from "axios"
import useAlert from "@/services/shared/alerts/useAlert"
import store from '@/store'
import config from "@/core/config/httpConfig"


export default class ApiService {
    alertMessage
    httpService

    constructor() {
        this.alertMessage = new useAlert()
        this.httpService = new HttpService(axios)
    }


    // showSuccessAlert is false by default because it's bad to show an alert on every GET request
    GET(endPoint, params = {}, showSuccessAlert = false) {
        store.commit('app/UPDATE_LOADER_STATE', true)
        return new Promise((resolve, reject) => {
            this.httpService.axiosIns
                .get(config.apiUrl + endPoint, {params: params})
                .then(response => {
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    if (response.data.status_code === 200) {
                        if (showSuccessAlert)
                            this.alertMessage.alertMessage(response.data)
                        return resolve(response.data.data)
                    } else {
                        this.alertMessage.alertMessage(response.data)
                        return reject(response.data)
                    }
                })
                .catch(error => {
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    this.alertMessage.alertMessage(error)
                })
        })
    }

    POST(endPoint, data, params = {}, showSuccessAlert = true) {
        store.commit('app/UPDATE_LOADER_STATE', true)
        return new Promise((resolve, reject) => {
            this.httpService.axiosIns
                .post(config.apiUrl + endPoint, data, params)
                .then(response => {
                    console.log(response)
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    if (response.data.status_code === 200) {
                        if (showSuccessAlert)
                            this.alertMessage.alertMessage(response.data)
                        return resolve(response.data.data)
                    } else {
                        console.log('response.data', response.data)
                        this.alertMessage.alertMessage(response.data)
                        return reject(response.data)
                    }
                })
                .catch(error => {
                    console.log('message => ',)
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    console.log(error)
                    this.alertMessage.alertMessage(error)
                })
        })
    }

    PUT(endPoint, data, params = {}, showSuccessAlert = true) {
        store.commit('app/UPDATE_LOADER_STATE', true)
        return new Promise((resolve, reject) => {
            this.httpService.axiosIns
                .put(config.apiUrl + endPoint, data, params)
                .then(response => {
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    if (response.data.status_code === 200) {
                        if (showSuccessAlert)
                            this.alertMessage.alertMessage(response.data)
                        return resolve(response.data.data)
                    } else {
                        this.alertMessage.alertMessage(response.data)
                        return reject(response.data)
                    }
                })
                .catch(error => {
                    console.log('message => ',)
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    this.alertMessage.alertMessage(error)
                })
        })
    }

    DELETE(endPoint, params = {}, showSuccessAlert = true) {
        store.commit('app/UPDATE_LOADER_STATE', true)
        return new Promise((resolve, reject) => {
            this.httpService.axiosIns
                .delete(config.apiUrl + endPoint, params)
                .then(response => {
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    if (response.data.status_code === 200) {
                        if (showSuccessAlert)
                            this.alertMessage.alertMessage(response.data)
                        return resolve(response.data.data)
                    } else {
                        this.alertMessage.alertMessage(response.data)
                        return reject(response.data)
                    }
                })
                .catch(error => {
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    this.alertMessage.alertMessage(error)
                })
        })
    }

    PATCH(endPoint, data, params = {}, showSuccessAlert = true) {
        store.commit('app/UPDATE_LOADER_STATE', true)
        return new Promise((resolve, reject) => {
            this.httpService.axiosIns
                .patch(config.apiUrl + endPoint, data, params)
                .then(response => {
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    if (response.data.status_code === 200) {
                        if (showSuccessAlert)
                            this.alertMessage.alertMessage(response.data)
                        return resolve(response.data.data)
                    } else {
                        this.alertMessage.alertMessage(response.data)
                        return reject(response.data)
                    }
                })
                .catch(error => {
                    console.log('message => ',)
                    store.commit('app/UPDATE_LOADER_STATE', false)
                    this.alertMessage.alertMessage(error)
                })
        })
    }
}
